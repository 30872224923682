import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import ContentBox from "../components/content-box"
import SectionHeader from "../components/headers/section-header"
import { faBackward } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TapeLabelHeader from "../components/headers/tape-label-header"
import GatsbyImage from "gatsby-image"
import PageHeader from "../components/headers/page-header"
import _ from "lodash"
import { OutboundLink } from "gatsby-plugin-google-analytics"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <PageHeader><a href={"/blog"}>Blog</a></PageHeader>

        <div css={css`margin-top:40px;`}>
          <ContentBox>
            {post.frontmatter.link && <OutboundLink target={'_blank'} href={post.frontmatter.link}>
              <SectionHeader align={"left"}>{post.frontmatter.title}</SectionHeader>
            </OutboundLink>}
            {!post.frontmatter.link &&
            <Link to={post.fields.slug} title={post.frontmatter.title}>
              <SectionHeader align={"left"}>{post.frontmatter.title}</SectionHeader>
            </Link>}
            <small>{post.frontmatter.date}</small>
            {post.frontmatter.link && <OutboundLink target={"_blank"} href={post.frontmatter.link}>
              {post.frontmatter.featuredImg && <GatsbyImage fluid={post.frontmatter.featuredImg.childImageSharp.fluid} alt={post.frontmatter.title}
                                                            css={css`width:200px; float:left; margin-right: 20px;`}/>}
              {post.frontmatter.image && <GatsbyImage fluid={post.frontmatter.image.childImageSharp.fluid} alt={post.frontmatter.title}
                                                      css={css`width:200px; float:left; margin-right: 20px;`}/>}
            </OutboundLink>}
            {!post.frontmatter.link &&
            post.frontmatter.featuredImg && <GatsbyImage fluid={post.frontmatter.featuredImg.childImageSharp.fluid} alt={post.frontmatter.title}
                                                         css={css`width:200px; float:left; margin-right: 20px;`}/>}
            {post.frontmatter.image && <GatsbyImage fluid={post.frontmatter.image.childImageSharp.fluid} alt={post.frontmatter.title}
                                                    css={css`width:200px; float:left; margin-right: 20px;`}/>
            }
            <p
              dangerouslySetInnerHTML={{ __html: post.html }}/>
            <div>
              {post.frontmatter.categories.map(category => {
                return (<Link key={category} to={`/categories/${_.kebabCase(category)}`} title={category}><kbd>{category}</kbd></Link>)
              })}
            </div>
            <hr css={css`margin-bottom: 20px;`}/>

            <footer css={css`
              a {
                color: #101010;
                &:hover {
                  color: #101010;
                  text-decoration: underline;
                  h1 { text-decoration: underline; }
                }
              }
            `}>
              <nav>
                <ul
                  css={css`
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  list-style: none;
                  padding: 0;
                `}
                >
                  <li>
                    {previous && (
                      <Link to={previous.fields.slug} rel="next" title={previous.frontmatter.title}>
                        <div css={css`display:flex;flex-direction:row;flex-wrap: nowrap;`}>
                          <div css={css`margin-right:20px;align-self: center;font-size:24px;`}><FontAwesomeIcon
                            icon={faBackward}/></div>
                          <div css={css`display:flex;flex-direction: column; flex-wrap: nowrap; text-align: left;`}>
                            <TapeLabelHeader>{previous.frontmatter.title}</TapeLabelHeader>
                            <small>{previous.frontmatter.date}</small>
                          </div>
                        </div>
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={next.fields.slug} rel="next" title={next.frontmatter.title}>
                        <div css={css`display:flex;flex-direction:row;flex-wrap: nowrap;`}>
                          <div css={css`display:flex;flex-direction: column; flex-wrap: nowrap; text-align: left;`}>
                            <TapeLabelHeader>{next.frontmatter.title}</TapeLabelHeader>
                            <small>{next.frontmatter.date}</small>
                          </div>
                          <div css={css`margin-left:20px;align-self: center;font-size:24px;`}><FontAwesomeIcon
                            icon={faBackward} flip={"horizontal"}/></div>
                        </div>
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </footer>
          </ContentBox>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            fields { slug }
            frontmatter {
                categories
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
    }
`
